// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deal-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 0 10px;
    font-family: 'Roboto', sans-serif;
}

.deal-item {
    background-color: #6B9080;
    border-radius: 10px;
    padding: 10px;
    color: #333232;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
}

.deal-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.deal-cards {
    margin-top: 5px;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/styles/DealList.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,yBAAyB;IACzB,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,iCAAiC;AACrC;;AAEA;IACI,eAAe;IACf,eAAe;IACf,iCAAiC;AACrC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');\n\n.deal-list {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 10px 10px 0 10px;\n    font-family: 'Roboto', sans-serif;\n}\n\n.deal-item {\n    background-color: #6B9080;\n    border-radius: 10px;\n    padding: 10px;\n    color: #333232;\n    display: flex;\n    flex-direction: column;\n    font-family: 'Roboto', sans-serif;\n}\n\n.deal-header {\n    display: flex;\n    justify-content: space-between;\n    font-weight: bold;\n    font-family: 'Roboto', sans-serif;\n}\n\n.deal-cards {\n    margin-top: 5px;\n    font-size: 24px;\n    font-family: 'Roboto', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
