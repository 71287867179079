// src/pages/Settings.js
import React, { useEffect, useState } from 'react';
import '../styles/Settings.css';

function Settings() {
    const [user, setUser] = useState(null);
    const [otherUserId, setOtherUserId] = useState('');

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const sendNotification = async (userId) => {
        try {
            const response = await fetch('https://api.telegram.org/bot7344166368:AAF7IS1pW_b8-9KSyvCImKzmYzaZhXbLgRw/sendMessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: userId,
                    text: 'ВАМ ОТВЕТИЛ ' + user.username,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Notification sent:', data);
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        setUser(null);
        window.location.reload(); // Обновление страницы
    };

    return (
        <div className="settings-content">
            <div className="settings-header">
                <h2>Settings</h2>
            </div>
            {user ? (
                <div className="user-info">
                    {user.username && <p>Username: @{user.username}</p>}
                    <p>ID: {user.id}</p>
                </div>
            ) : (
                <p>No user data available</p>
            )}
            <div className="buttons">
                <button onClick={() => sendNotification(user.id)}>Send Notification to Self</button>
                <input
                    type="text"
                    placeholder="Enter other user ID"
                    value={otherUserId}
                    onChange={(e) => setOtherUserId(e.target.value)}
                />
                <button onClick={() => sendNotification(otherUserId)}>Send Notification to Other User</button>
                <button onClick={handleLogout}>Logout</button>
            </div>
        </div>
    );
}

export default Settings;