import React from 'react';
import DealList from "./DealList";

function PartnersTurn() {
    return (
        <div>
            <DealList/>
        </div>
    );
}

export default PartnersTurn;