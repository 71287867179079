import React, { useState } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import MyTurn from './MyTurn';
import PartnersTurn from './PartnersTurn';
import Finished from './Finished';
import NewBoardPage from '../pages/NewBoardPage';
import SettingsPage from '../pages/SettingsPage';
import '../styles/Tabs.css';

function Tabs() {
    const [activeTopTab, setActiveTopTab] = useState('myTurn');
    const location = useLocation();

    const renderTopContent = () => {
        switch (activeTopTab) {
            case 'myTurn':
                return <MyTurn />;
            case 'partnersTurn':
                return <PartnersTurn />;
            case 'finished':
                return <Finished />;
            default:
                return null;
        }
    };

    return (
        <div>
            <Routes>
                <Route path="/new-board" element={<NewBoardPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/" element={
                    <>
                        <div className="tabs top-tabs">
                            <button className={activeTopTab === 'myTurn' ? 'active' : ''} onClick={() => setActiveTopTab('myTurn')}>My Turn</button>
                            <button className={activeTopTab === 'partnersTurn' ? 'active' : ''} onClick={() => setActiveTopTab('partnersTurn')}>Partner's Turn</button>
                            <button className={activeTopTab === 'finished' ? 'active' : ''} onClick={() => setActiveTopTab('finished')}>Finished</button>
                        </div>
                        <div className="tab-content">
                            {renderTopContent()}
                        </div>
                    </>
                } />
            </Routes>
            <div className="tabs bottom-tabs">
                <Link to="/" className="tab-link">
                    <button className={location.pathname === '/' ? 'active' : ''}>Boards</button>
                </Link>
                <Link to="/new-board" className="tab-link">
                    <button className={location.pathname === '/new-board' ? 'active' : ''}>New Board</button>
                </Link>
                <Link to="/settings" className="tab-link">
                    <button className={location.pathname === '/settings' ? 'active' : ''}>Settings</button>
                </Link>
            </div>
        </div>
    );
}

export default Tabs;