import React from 'react';
import '../styles/DealList.css';

const deals = [
    { id: 1, opponent: 'Player A', dealNumber: 43251, cards: '♠Q J 8 4 ♥A K Q 5 3 ♦9 ♣J 7 6' },
    { id: 2, opponent: 'Player B', dealNumber: 63433, cards: '♠Q J 8 4 ♥A K Q 5 3 ♦9 ♣J 7 6' },
    { id: 3, opponent: 'Player C', dealNumber: 12345, cards: '♠A K 10 7 ♥Q J 9 6 ♦8 5 ♣K Q 4' },
    { id: 4, opponent: 'Player D', dealNumber: 67890, cards: '♠J 9 6 3 ♥K Q 10 8 ♦A 7 4 ♣Q J 5' },
    { id: 5, opponent: 'Player E', dealNumber: 54321, cards: '♠K Q 7 5 ♥J 10 9 4 ♦A 6 3 ♣K 8 2' },
    { id: 6, opponent: 'Player F', dealNumber: 98765, cards: '♠A J 8 6 ♥K Q 9 5 ♦10 7 4 ♣J 3 2' },
    { id: 7, opponent: 'Player G', dealNumber: 11223, cards: '♠Q 10 9 4 ♥A K J 7 ♦8 6 5 ♣Q 3 2' },
    { id: 8, opponent: 'Player H', dealNumber: 44556, cards: '♠K J 8 5 ♥Q 10 9 6 ♦A 7 3 ♣K 4 2' },
    { id: 9, opponent: 'Player I', dealNumber: 77889, cards: '♠A Q 10 7 ♥K J 9 5 ♦8 6 4 ♣J 3 2' },
    { id: 10, opponent: 'Player J', dealNumber: 99001, cards: '♠K J 9 6 ♥Q 10 8 5 ♦A 7 4 ♣K 3 2' },
    { id: 11, opponent: 'Player K', dealNumber: 22334, cards: '♠A K Q 10 ♥J 9 8 6 ♦7 5 4 ♣Q 3' },
    { id: 12, opponent: 'Player L', dealNumber: 55667, cards: '♠Q J 9 7 ♥K 10 8 5 ♦A 6 4 ♣J 3' },
    { id: 13, opponent: 'Player M', dealNumber: 88990, cards: '♠K Q 10 6 ♥J 9 8 4 ♦A 7 3 ♣K 2' },
    { id: 14, opponent: 'Player N', dealNumber: 11234, cards: '♠A J 9 5 ♥K Q 10 7 ♦8 6 4 ♣J 3' },
    { id: 15, opponent: 'Player O', dealNumber: 33445, cards: '♠Q 10 8 6 ♥A K J 5 ♦9 7 4 ♣Q 3' },
    { id: 16, opponent: 'Player P', dealNumber: 66778, cards: '♠K J 9 4 ♥Q 10 8 6 ♦A 7 3 ♣K 2' },
    { id: 17, opponent: 'Player Q', dealNumber: 99012, cards: '♠A Q 10 7 ♥K J 9 5 ♦8 6 4 ♣J 3' },
    { id: 18, opponent: 'Player R', dealNumber: 12345, cards: '♠K J 8 5 ♥Q 10 9 6 ♦A 7 3 ♣K 4' },
    { id: 19, opponent: 'Player S', dealNumber: 45678, cards: '♠A K Q 10 ♥J 9 8 6 ♦7 5 4 ♣Q 3' },
    { id: 20, opponent: 'Player T', dealNumber: 78901, cards: '♠Q J 9 7 ♥K 10 8 5 ♦A 6 4 ♣J 3' },
];

function DealList() {
    return (
        <div className="deal-list">
            {deals.map(deal => (
                <div key={deal.id} className="deal-item">
                    <div className="deal-header">
                        <span>{deal.dealNumber}</span>
                        <span>{deal.opponent}</span>
                    </div>
                    <div className="deal-cards">
                        {deal.cards}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DealList;