// src/components/TelegramAuth.js
import React, { useEffect } from 'react';
import '../styles/TelegramAuth.css';

const TelegramAuth = ({ onAuth }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?22';
        script.async = true;
        script.setAttribute('data-telegram-login', 'bridge43_bot');
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-onauth', 'onTelegramAuth(user)');
        script.setAttribute('data-request-access', 'write');
        document.getElementById('telegram-login').appendChild(script);

        window.onTelegramAuth = (user) => {
            alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
            onAuth(user);
        };
    }, [onAuth]);

    return (
        <div className="telegram-auth-container">
            <div id="telegram-login"></div>
        </div>
    );
};

export default TelegramAuth;