import React from 'react';
import { Link } from 'react-router-dom';
import Settings from './Settings';
import '../styles/Tabs.css';

function SettingsPage() {
    return (
        <div>
            <Settings />
            <div className="tabs bottom-tabs">
                <Link to="/public" className="tab-link">
                    <button>Boards</button>
                </Link>
                <Link to="/new-board" className="tab-link">
                    <button>New Board</button>
                </Link>
                <Link to="/src/pages/Settings" className="tab-link">
                    <button className="active">Settings</button>
                </Link>
            </div>
        </div>
    );
}

export default SettingsPage;