// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
}

.app {
    background: #CCE3DE;
    height: 100%;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":["/* App.css */\nhtml, body {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    width: 100%;\n}\n\n#root {\n    height: 100%;\n}\n\n.app {\n    background: #CCE3DE;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
